import { NgModule } from '@angular/core';
import { FullCalendarComponent } from './fullcalendar.component';

@NgModule({
  declarations: [FullCalendarComponent],
  imports: [
  ],
  exports: [FullCalendarComponent]
})
export class FullCalendarModule { }
